<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="bloquearVista == true"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container v-else xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Pedidos'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        @datosTabla="datosTabla"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="true"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar mr-3 pr-0" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>

                            <v-menu offset-y content-class="menuClassAcciones ">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        class="btnAcciones pr-1 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        Acciones 
                                        <template  v-for="(item,valor) in attrs" >
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                                        </template>
                                    </v-btn>
                                    
                                </template>
                                <div class="btnAccionesContenedor">
                                    <div @click="abrirModalLayout('reporte')" class="btnAccionesItem py-2">
                                        <span>Descargar reporte</span>
                                    </div>
                                    <div @click="abrirModalLayout('reporteCancelados')" class="btnAccionesItem py-2">
                                        <span>Descargar reporte de cancelados</span>
                                    </div>
                                </div>
                            </v-menu>
                        </template>

                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin' || rol =='admin-empresa'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="sucursales"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Sucursal"
                                    persistent-hint
                                    v-model="sucursal_value"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>
                            
                            <v-col v-if="rol != 'empleado' && rol != 'admin-depto'" cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    :items="empleados"
                                    item-text="nombre_completo"
                                    item-value="id"
                                    label="Empleado"
                                    persistent-hint
                                    v-model="pedido.empleado_id"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    :items="estatus"
                                    item-text="descripcion"
                                    item-value="valor"
                                    label="Estatus"
                                    persistent-hint
                                    v-model="estatusModel"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>

                            

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    label="Fecha mínima"
                                    :valorDefecto="fecha_min"
                                    v-model="fecha_min"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaMinima"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    label="Fecha máxima"
                                    :valorDefecto="fecha_max"
                                    v-model="fecha_max"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaMaxima"
                                />
                            </v-col>
                        </template>

                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <template v-if="rol!='empleado' && rol!='admin-depto'">
                                    <td>
                                        <div class="tblPrincipal">{{ item.nombre_empleado }}</div>
                                    </td>
                                    <td>
                                        <div class="tblPrincipal">{{ item.sucursal }}</div>
                                    </td>
                                </template>
                                
                                <td>
                                    <div >
                                        <v-list  color="transparent" class="py-0 my-0">
                                            <v-list-item class="py-0 my-0 px-0">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-list-item-content v-on="on" class="ml-0 py-0 my-0">
                                                            <v-list-item-title class="tblPrincipal overflow-x-hidden">{{ item.cocina }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                    <span>{{ item.cocina }}</span>
                                                 </v-tooltip>  
                                            </v-list-item>
                                        </v-list>
                                    </div> 
                                </td>
                                <td>
                                    <div class="tblPrincipal">
                                        <p class="pa-0 ma-0">{{ item.guiso }}</p>
                                        <p v-if="item.cancelado" class="pa-0 ma-0 canceladoText">Cancelado</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ dateFormat(item.fecha_entrega) }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ formatoMoneda(item.precio_empleado) }}</div>
                                </td>
                                <td>
                                    <div class="tblOpciones">

                                        <v-menu v-if="item.cancelado" v-model="item.menu" left buttom :close-on-content-click="false" class="elevation-0 d-inline" content-class="mt-0 menuClassAccionesVer menu-width"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    color="transparent"
                                                    rounded
                                                    class="elevation-0 botonHover"
                                                    icon 
                                                    fab 
                                                    dark 
                                                    small
                                                    :ripple="false" 
                                                >
                                                    <v-icon class="iconoDelgadoBoton tamIconoBoton" v-text="'$visibility_outline'"></v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <div xs="1" sm="1" md="1" lg="1" class="v-btn-alineacion background-transparent" style="float: right;">
                                                    <v-btn
                                                        @click="item.menu = false"
                                                        small
                                                        icon
                                                        text
                                                        :ripple="false" 
                                                        class="v-btn-cerrar mr-2"
                                                    >
                                                        <v-icon class="icono-cerrar"></v-icon>
                                                    </v-btn>
                                                </div>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-subtitle>
                                                            <v-row class="pa-0 ma-0">
                                                                <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                                    <p class="informacionGeneralTitulo">Descripción</p>
                                                                </v-col> 
                                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Nombre:</p>
                                                                        <p class="informacionGeneralText">{{item.guiso ? item.guiso:"Información faltante*"}}</p>
                                                                    </v-col>
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Cocina:</p>
                                                                        <p class="informacionGeneralText">{{item.cocina ? item.cocina:"Información faltante*"}}</p>
                                                                    </v-col>
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Fecha de pedido:</p>
                                                                        <p class="informacionGeneralText text-wrap">{{dateFormat(item.fecha_entrega, 'modal')}}</p>
                                                                    </v-col>
                                                                    
                                                                    <v-divider class="divider"></v-divider>

                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0 mb-4">
                                                                        <p class="mb-0 informacionCancelacion ">Información de cancelación:</p>
                                                                    </v-col>
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Responsable:</p>
                                                                        <p class="informacionGeneralText text-wrap">{{item.cancelacion[0].responsable}}</p>
                                                                    </v-col>
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Fecha de cancelación:</p>
                                                                        <p class="informacionGeneralText text-wrap">{{dateFormat(item.cancelacion[0].fecha, 'modal')}}</p>
                                                                    </v-col>
                                                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                        <p class="mb-0 informacionGeneralSubtitle">Motivo:</p>
                                                                        <p class="informacionGeneralText text-wrap">{{item.cancelacion[0].motivo}}</p>
                                                                    </v-col>
                                                                </v-col>
                                                            </v-row>                           
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>

                                        <template v-if="!item.cancelado">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        slot="activator"
                                                        class="botonHover"
                                                        icon
                                                        fab
                                                        dark
                                                        @click="abrirModal('update', item)"
                                                        small
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Editar</span>
                                            </v-tooltip>
                                            <v-tooltip bottom v-if="permisos.cancelar">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        slot="activator"
                                                        class="botonHover"
                                                        depressed
                                                        icon
                                                        fab
                                                        dark
                                                        small
                                                        @click="abrirModalCancelar(item)"
                                                    >
                                                        <v-icon class="iconoDelgadoBotonDelete">no_meals</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Cancelar pedido</span>
                                            </v-tooltip>
                                        </template>
                                        
                                    </div>
                                </td>
                            </tr>
                        </tbody>


                    </data-table>
                </v-col>

                <v-col cols="12" lg="12" class="px-0 mx-0">
                    <div class="totales d-flex justify-end">
                        <table class="totales-table">
                            <tbody class="totales-tbody">
                                <tr v-if="rol!='empleado'&& rol!='admin-depto'" class="totales-tr">
                                    <td class="totales-td01">
                                        Costo total empresa
                                    </td>
                                    <td class="totales-td02">
                                        {{ formatoMoneda(precioEmpresa) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="totales-td01">
                                        Costo total empleado 
                                    </td>
                                    <td class="totales-td02">
                                        {{ formatoMoneda(precioEmpleado) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="750px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>
                            <!-- <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title> -->

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-row class="my-5">
                                        <template v-if="accion == 'add'">
                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="cliente" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="clientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Cliente"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="empresa" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="empresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Empresa"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                        
                                        <v-col v-if="rol == 'root' || rol == 'admin' || rol =='admin-empresa'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="sucursal" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="sucursales"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Sucursal"
                                                    persistent-hint
                                                    v-model="sucursal_value"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        
                                        <v-col v-if="rol != 'empleado' && rol != 'admin-depto'" cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="empleados"
                                                    item-text="nombre_completo"
                                                    item-value="id"
                                                    label="Empleado"
                                                    persistent-hint
                                                    v-model="pedido.empleado_id"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Ubicación de entrega" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="sucursales"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Ubicación de entrega"
                                                    persistent-hint
                                                    v-model="sucursal_value_ubicacion"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                label="Fecha"
                                                :valorDefecto="pedido.fecha_entrega"
                                                v-model="pedido.fecha_entrega"
                                                placeholder="dd/mm/aaaa"
                                                ref="Fecha"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cocina" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="cocinas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Cocina"
                                                    persistent-hint
                                                    v-model="pedido.cocina_id"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                    :disabled="(pedido.fecha_entrega == null || pedido.fecha_entrega == '') ? true:false"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        
                                        
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <h4 class="subtitulos mb-3">Menú de platillos fijos y del día</h4>
                                            <template v-if="itemsGuisos.length > 0">
                                                <v-radio-group v-model="pedido.guiso_id">
                                                    <v-radio
                                                        v-for="n in itemsGuisos"
                                                        :key="n.id"
                                                        :label="`${n.nombre}`"
                                                        :value="n.id"
                                                        hideDetails
                                                    ></v-radio>
                                                </v-radio-group>
                                            </template>
                                            <div v-else class="d-flex justify-center align-center text-center background-area">
                                                <div class="my-10">Una vez elegida una cocina, las opciones <br> de los platillos apareceran aquí.</div>
                                            </div>
                                        </v-col>
                                        <v-col v-if="pedido.guiso_id" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-img
                                                min-height="200"
                                                max-height="250"
                                                max-width="250" 
                                                lazy-src="/static/modal/platillo_comida.png"
                                                :src="imageUrl" 
                                                style=""
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" class="pb-0">
                                            <h4 class="subtitulos mb-3">Elige tu complemento</h4>
                                            <template v-if="itemsComplementos.length > 0">
                                                <v-checkbox
                                                    v-for="(complemento, key, index) in itemsComplementos"
                                                    :key="complemento['id']+complemento['nombre']"
                                                    v-model="pedido.complementos"
                                                    :label="complemento['nombre']"
                                                    :value="complemento.id"
                                                    hide-details
                                                ></v-checkbox>
                                            </template>
                                            <div v-else class="d-flex justify-center align-center text-center background-area">
                                                <div class="my-10">Una vez elegida una cocina, las opciones <br> de los complementos apareceran aquí.</div>
                                            </div>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" class="pb-0 pt-5">
                                            <v-textarea
                                                outlined
                                                label="Comentarios"
                                                :rows="2"
                                                v-model="pedido.comentarios"
                                            ></v-textarea>
                                        </v-col>

                                        

                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <h4 class="subtitulos">Costo</h4>
                                            <p class="precio">{{ precioGuiso }}</p>
                                        </v-col>
                                        
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                    v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                            <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                >
                                    El usuario no tiene permiso para editar el registro.
                                </v-alert>
                            </div>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template>
                <v-dialog v-model="dialogLayout" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayout"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="formLayout" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloLayout }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Cliente"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    :items="empresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Empresa"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="rol == 'root' || rol == 'admin' || rol =='admin-empresa'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                :items="sucursales"
                                                item-text="nombre"
                                                item-value="id"
                                                label="Sucursal"
                                                persistent-hint
                                                v-model="sucursal_value"
                                                no-data-text="Datos no disponibles"
                                            />
                                        </v-col>
                                        
                                        <v-col v-if="rol != 'empleado'" cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <v-autocomplete
                                                outlined
                                                :items="empleados"
                                                item-text="nombre_completo"
                                                item-value="id"
                                                label="Empleado"
                                                persistent-hint
                                                v-model="pedido.empleado_id"
                                                no-data-text="Datos no disponibles"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                label="Fecha mínima"
                                                :valorDefecto="fecha_min"
                                                v-model="fecha_min"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaMinima"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                label="Fecha máxima"
                                                :valorDefecto="fecha_max"
                                                v-model="fecha_max"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaMaxima"
                                            />
                                        </v-col>

                                        <v-col v-if="accionLayout === 'importar'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="archivoLayout"
                                                    accept=".xlsx"
                                                    required 
                                                    :error-messages="errors"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                >
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'importar'" @click="guardarLayout()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'exportar'" @click="descargarLayout()" :disabled="invalid" :loading="isSaving">Descargar</v-btn>
                                <v-btn class="btnGuardar" v-if="accionLayout === 'reporte' || accionLayout==='reporteCancelados'" @click="descargarReporte()" :loading="isSaving">Descargar</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>


            <template>
                <v-dialog v-model="dialogCancelar" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal"></v-btn>
                    </template>
                    <ValidationObserver ref="formCancelar" v-slot="{ invalid }">
                        <v-card>
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho d-flex justify-center">
                                <div class="header-cancelar">
                                    <img src="/static/icon/alert_warning.png" alt="Imagen cancelar" class="mb-5">
                                    <h2 class="header-cancelar-h2 mb-4">Cancelar pedido</h2>
                                    <h2 class="header-cancelar-h3 mb-5">¿Deseas cancelar este pedido?</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pb-0 pt-5">
                                            <ValidationProvider v-slot="{ errors }" name="Motivo" rules="required">
                                                <v-textarea
                                                    outlined
                                                    label="Motivo de cancelación"
                                                    :rows="2"
                                                    v-model="cancelarPedido.motivo_cancelacion"
                                                    :error-messages="errors"
                                                ></v-textarea>
                                            </ValidationProvider>
                                        </v-col>
                                        
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button 
                                    class="btnCerrar" 
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                    >Cerrar
                                </button>
                                <v-btn
                                    class="btnCancelar ml-4"
                                    @click="cancelar()" 
                                    :loading="isSaving"
                                    :disabled="invalid" 
                                >
                                    <span class="mx-3">Cancelar pedido</span>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
/* API's */
import apiCocinas from "@/api/comidas/cocinas";
import apiGuisos from "@/api/comidas/guisos";
import apiComplementos from '@/api/comidas/complementos';
import apiPedidos from "@/api/comidas/pedidos";
import apiClientes from '@/api/clientes';
import apiEmpresas from '@/api/empresas';
import apiSucursales from '@/api/sucursales';
import apiEmpleados from '@/api/empleados';

/* COMPONENTES */
import Datatable from "@/components/datatable/Datatable.vue";
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete2.vue';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

//PLUGINS
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";

import moment from "moment";
moment.locale("es");


export default {
    components: {
        "data-table": Datatable,
        VuetifyMultipleAutocomplete,
        VuetifyDatePicker,
    },

    data() {
        return {
            datosLogin  : null,
            rol         : null,
            url: "comida-pedido/find",
            columns: [
                {
                    label: "Empleado",
                    name: "nombre_completo",
                    filterable: false,
                },
                {
                    label: "Sucursal",
                    name: "sucursal",
                    filterable: false,
                },
                {
                    label: "Cocina",
                    name: "cocina",
                    filterable: false,
                },
                {
                    label: "Comida",
                    name: "comida",
                    filterable: false,
                },
                {
                    label: "Fecha del pedido",
                    name: "fecha",
                    filterable: false,
                },
                {
                    label: "Costo",
                    name: "costo",
                    filterable: false,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    filterable: false,
                    align: 'center'
                },
            ],
            filters: {
                paginate    : true,
                order_by    : 'nombre',
                cliente_id  : null,
                empresa_id  : null,
                sucursal_id : null,
                empleado_id : null,
                fecha_min   : null,
                fecha_max   : null,
                activo      : null,
                cancelado   : null,
            },
            pedido: {
                id              : null,
                guiso_id        : null,
                empleado_id     : null,
                cocina_id       : null,
                fecha_entrega   : null,
                comentarios     : null,
                complementos    : [],
                fecha_pedido    : null,
                ubicacion_id    : null,
            },
            tituloModal     : '',
            imagen          : '',
            accion          : '',
            isLoadingModal  : false,
            isLoading       : false,
            dialog          : false,
            isSaving        : false,
            cliente_value   : null,
            empresa_value   : null,
            sucursal_value  : null,
            clientes        : [],
            empresas        : [],
            sucursales      : [],
            empleados       : [],
            cocinas         : [],
            itemsGuisos     : [],
            itemsComplementos: [],
            dialogLayout    : false,
            tituloLayout    : '',
            archivoLayout   : null,
            accionLayout    : '',
            fecha_min       : null,
            fecha_max       : null,

            dialogCancelar  : null,
            cancelarPedido  : {
                id: null,
                motivo_cancelacion: "",
                fecha_pedido: null,
            },
            estatusModel: 'todos',
            estatus : [
                {
                    descripcion: "Todos",
                    valor: "todos"
                },
                {
                    descripcion: "Activos",
                    valor: "activos"
                },
                {
                    descripcion: "Cancelados",
                    valor: 'cancelados'
                }
            ],

            precioEmpresa:0,
            precioEmpleado: 0,
            urlImg : GetUrlImg.getURL(),
            bloquearVista: false,
            sucursal_value_ubicacion:null,

            permisos :{
                create: false,
                update: false,
                delete: false,
                cancelar: false
            }

        }
    },
    watch: {
        cliente_value: function(val) {
            if(val != null){
                this.getEmpresas(val);
            }
        },
        empresa_value: function(val, oldval) {
            if(oldval != null){
                this.sucursal_value = null;
                this.sucursal_value_ubicacion = null;  
            }
            if(val != null){
                this.getSucursales(val);
            }
        },
        sucursal_value: function(val) {
            if(val != null){
                this.getEmpleados();
                this.getCocinas();
            }
        },
        "pedido.fecha_entrega":function(val, oldval){
            if(val != null && this.pedido.cocina_id != null){
                this.getGuisos();
                this.getComplementos();
            }

            if(oldval != null){
                this.pedido.guiso_id = null;
                this.pedido.complementos = [];
            }
        },
        "pedido.cocina_id": function(val, oldval){
            if(val != null && this.pedido.fecha_entrega != null){
                this.getGuisos();
                this.getComplementos();
            }

            if(oldval != null){
                this.pedido.guiso_id = null;
                this.pedido.complementos = [];
            }
        },
    },
    computed: {
        precioGuiso:function() {
            if(this.pedido.guiso_id != null){
                if(this.itemsGuisos.length>0){
                    let guiso = null;
                    guiso = this.itemsGuisos.find(n => n.id === this.pedido.guiso_id);
                    if(guiso != null){
                        return this.formatoMoneda(parseFloat(guiso.precio))
                    }
                }
            }
            else {
                return this.formatoMoneda(0);
            }
        },
        imageUrl:function() {
            if(this.pedido.guiso_id != null){
                if(this.itemsGuisos.length>0){
                    let guiso = null;
                    guiso = this.itemsGuisos.find(n => n.id === this.pedido.guiso_id);
                    if(guiso != null){
                        return this.urlImg + guiso.foto
                    }
                }
            }
            else {
                return '/static/modal/platillo_comida.png';
            }
        },


        //this.imageUrl           = this.urlImg + data.foto
    },
    methods: {
        datosTabla(data){
            if(data.data){
                if(data.data.length>0){
                    this.precioEmpresa=0;
                    this.precioEmpleado=0;
                    data.data.forEach(pedido => {
                        if(!pedido.cancelado){
                            this.precioEmpresa = this.precioEmpresa + parseFloat(pedido.precio_empresa);
                            this.precioEmpleado = this.precioEmpleado + parseFloat(pedido.precio_empleado);
                        }
                    });
                }
                else{
                    this.precioEmpresa = parseFloat(0);
                    this.precioEmpleado = parseFloat(0);
                }
            }
        },
        async abrirModal(accion, data = null){
            await this.resetValues()
            this.accion         = accion;
            this.isLoadingModal = true;

            if(accion == "add"){
                this.tituloModal    = "Agregar nuevo pedido";
                this.imagen         = "/static/modal/brigadasCreate.svg";

                if(this.rol == 'root'){
                    this.getClientes();
                }
                else if(this.rol == 'admin'){
                    this.cliente_value = this.datosLogin.cliente_id;
                }
                else if(this.rol == 'admin-empresa'){
                    this.empresa_value = this.datosLogin.empresa_id;
                }
                else if(this.rol == 'admin-sucursal'){
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.sucursal_value = this.datosLogin.sucursal_id;
                }
                else if(this.rol == 'admin-depto'){
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.sucursal_value = this.datosLogin.sucursal_id;
                }
                else {
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.sucursal_value = this.datosLogin.sucursal_id;
                    this.sucursal_value_ubicacion = this.datosLogin.sucursal_id;
                }


                
            } else {
                this.tituloModal            = "Editar pedido";
                this.imagen                 = "/static/modal/brigadasUpdate.svg";
                this.cliente_value          = data.cliente_id;
                this.empresa_value          = data.empresa_id;
                this.sucursal_value         = data.sucursal_id;
                this.pedido.id              = data.id;
                this.pedido.guiso_id        = data.guiso_id;
                this.pedido.empleado_id     = data.empleado_id;
                this.pedido.cocina_id       = data.cocina_id;
                this.pedido.fecha_entrega   = data.fecha_entrega;
                this.pedido.comentarios     = data.comentarios;
                this.sucursal_value_ubicacion = data.ubicacion_id; //// cambiar

                let complementos = [];
                complementos = data.complementos.map(item => {
                    return item.id
                })
                this.pedido.complementos = complementos;
            }
            this.isLoadingModal  = false;
            this.dialog = true;
        },

        cerrarModal() {
            this.dialog     = false;
            this.dialogLayout = false;
            this.dialogCancelar = false;
            this.isSaving   = false;
            this.resetValues();
        },
        async resetValues() {
            this.pedido= {
                id              : null,
                guiso_id        : null,
                empleado_id     : null,
                cocina_id       : null,
                fecha_entrega   : null,
                comentarios     : null,
                complementos    : [],
                fecha_pedido    : null,
                ubicacion_id    : null,
            };

            this.empleados           = [];
            this.cocinas             = [];
            this.itemsGuisos         = [];
            this.itemsComplementos   = [];

            this.archivoLayout  = null;
            this.tituloLayout   = '';
            this.accionLayout   = '';
            this.fecha_min      = null;
            this.fecha_max      = null;

            this.estatusModel = "todos";

            this.isLoading = false;

            this.cancelarPedido  = {
                id: null,
                motivo_cancelacion: "",
                fecha_pedido: null,
            }

            if(this.rol == 'root'){
                this.getClientes();
                this.cliente_value = null;
                this.empresa_value = null;
                this.sucursal_value = null;
                this.empresas = [];
                this.sucursales = [];
                
            }
            else if(this.rol == 'admin'){
                this.empresa_value = null;
                this.sucursal_value = null;
                this.sucursales = [];
                
            }
            else if(this.rol == 'admin-empresa'){
                this.sucursal_value = null;
               
            }
            else {
                this.sucursal_value = null;
            }

            this.$nextTick(() => {
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
                if(this.$refs.formLayout){
                    this.$refs.formLayout.reset();
                }
            });
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el pedido " + data.nombre + "?",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiPedidos.delete(data.id).then(response => {
                        Notify.Success("Pedido eliminado", "El pedido ha sido eliminado satisfactoriamente.");
                        this.$refs.tabla.getData();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        abrirModalCancelar(item){
            this.dialogCancelar = true;
            this.cancelarPedido.id = item.id;

        },
        cancelar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas cancelar el pedido?",
                "Cancelar pedido",
                () => {
                    this.isSaving = true;
                    let hoy = moment().format("YYYY-MM-DD HH:mm:ss");
                    this.cancelarPedido.fecha_pedido = hoy

                    apiPedidos.cancelar(this.cancelarPedido).then(response => {
                        Notify.Success("Pedido cancelado", "El pedido ha sido cancelado satisfactoriamente.");
                        this.cerrarModal();
                        this.$refs.tabla.getData();
                    })
                    .catch(err => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                if(this.pedido.guiso_id == null){
                    Notify.ErrorToast("Elige un platillo");
                    this.isSaving = false;
                    return;
                }

                if(this.rol == 'empleado'){
                    this.pedido.empleado_id = this.datosLogin.empleado_id;
                }
                else if (this.rol == 'admin-depto'){
                    this.pedido.empleado_id = this.datosLogin.empleado_id;
                }

                let hoy = moment().format("YYYY-MM-DD HH:mm:ss");
                this.pedido.fecha_pedido = hoy

                this.pedido.ubicacion_id = this.sucursal_value_ubicacion

                this.isLoading = true;
                if(this.accion === "add") {
                    apiPedidos.store(this.pedido).then((response) => {
                        Notify.Success("Operación exitosa", "El pedido se guardó satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiPedidos.update(this.pedido).then((response) => {
                        Notify.Success("Operación exitosa", "El pedido se actualizó satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        async loadModalData(){
            await this.resetValues()
            if(this.rol == 'root'){
                await this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
            else {
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
        },
        async setFilters(){
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let sucursal = this.sucursal_value;
            let empleado = this.pedido.empleado_id;
            let fecha_min = this.fecha_min;
            let fecha_max = this.fecha_max;
            if(this.rol == 'root'){

            }
            else if (this.rol == "admin") {    
                cliente = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                cliente = this.datosLogin.cliente_id;
                empresa= this.datosLogin.empresa_id;
                sucursal = this.datosLogin.sucursal_id;
            }
            else if(this.rol == 'admin-depto'){
                cliente = this.datosLogin.cliente_id;
                empresa= this.datosLogin.empresa_id;
                sucursal = this.datosLogin.sucursal_id;
                empleado = this.datosLogin.empleado_id;
            }
            else if(this.rol == 'empleado'){
                cliente = this.datosLogin.cliente_id;
                empresa= this.datosLogin.empresa_id;
                sucursal = this.datosLogin.sucursal_id;
                empleado = this.datosLogin.empleado_id;
            }

            if(this.estatusModel == "todos"){
                this.filters.activo = null;
                this.filters.cancelado = null;
            }
            else if(this.estatusModel == "activos"){
                this.filters.activo = true;
                this.filters.cancelado = null;
            }
            else if(this.estatusModel == "cancelados"){
                this.filters.activo = null;
                this.filters.cancelado = true;
            }


            this.filters.cliente_id     = cliente;
            this.filters.empresa_id     = empresa;
            this.filters.sucursal_id    = sucursal;
            this.filters.empleado_id    = empleado;
            this.filters.fecha_min      = fecha_min;
            this.filters.fecha_max      = fecha_max;

            await this.$refs.tabla.getData();
            await this.resetValues();
        },
        getClientes(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre'
            }
            
            apiClientes.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        getEmpresas(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value
            }
            apiEmpresas.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        getSucursales(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value
            }
            apiSucursales.getSucursales(parametros).then((response) => {
                this.sucursales = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },
        getEmpleados(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value,
                sucursal_id : this.sucursal_value,
                rol_name    : this.rol,
                usuario_id  : this.datosLogin.id,
                tipo_empleado : 'ambos'

            }
            if(this.rol == 'empleado'){return}

            apiEmpleados.getEmpleados(parametros).then((response) => {
                this.empleados = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },
        getCocinas(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value,
                sucursal_id : this.sucursal_value,
            }
            apiCocinas.find(parametros).then((response) => {
                this.cocinas = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las cocinas.");
            });
        },
        getGuisos(){
            let parametros = {
                activo          : true,
                paginate        : false,
                order           : 'asc',
                order_by        : 'nombre',
                cocina_id       : this.pedido.cocina_id,
                fecha_busqueda  : this.pedido.fecha_entrega
            }

            apiGuisos.find(parametros).then((response) => {
                this.itemsGuisos = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los guisos.");
            });
        },
        getComplementos(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value,
                cocina_id   : this.pedido.cocina_id,
            }
            apiComplementos.find(parametros).then((response) => {
                this.itemsComplementos = response.data;
                
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los complementos.");
            });
        },
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },
        listar(){
            if(this.rol == 'root'){
                this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.filters.sucursal_id = this.datosLogin.sucursal_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
            else if(this.rol == 'admin-depto'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.filters.sucursal_id = this.datosLogin.sucursal_id;
                this.filters.empleado_id = this.datosLogin.empleado_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
            else if(this.rol == 'empleado'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.filters.sucursal_id = this.datosLogin.sucursal_id;
                this.filters.empleado_id = this.datosLogin.empleado_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
            else {
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.filters.sucursal_id = this.datosLogin.sucursal_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
        },
        abrirModalLayout(accionLayout = ''){
            this.accionLayout = accionLayout
            if(accionLayout == 'importar'){
                this.tituloLayout = "Importar layout de platillos"
            }
            else if(accionLayout == 'exportar'){
                this.tituloLayout = "Exportar layout de platillos"
            }
            else if(accionLayout == 'reporte'){
                this.tituloLayout = "Descargar reporte"
            }
            else if(accionLayout == 'reporteCancelados'){
                this.tituloLayout = "Descargar reporte de cancelados"
            }

            if(this.rol == 'admin-sucursal'){
                this.sucursal_value = this.datosLogin.sucursal_id;
            }

            this.dialogLayout = true;
        },
        
        async descargarReporte(){
            let self = this;
            this.$refs.formLayout.validate().then(async success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                this.isSaving = true;
                
                const FileDownload = require("js-file-download");
                let today = new Date();
                let nombre = '';

                nombre = "Reporte_pedidos_" +
                    today.getDate() +
                    "_" +
                    (today.getMonth() + 1) +
                    "_" +
                    today.getFullYear() +
                    ".xlsx";
                
                let parametros = {
                    cliente_id  : null,
                    empresa_id  : null,
                    sucursal_id : null,
                    fecha_min   : null,
                    fecha_max   : null,
                    empleado_id : null,
                    activo      : true,
                    cancelado   : false,
                }
                parametros.empleado_id = this.pedido.empleado_id;

                if(this.accionLayout == "reporteCancelados"){
                    parametros.activo = false;
                    parametros.cancelado = true;
                }

                if(this.rol == 'root'){
                    parametros.cliente_id = this.cliente_value;
                    parametros.empresa_id = this.empresa_value;
                    parametros.sucursal_id = this.sucursal_value;
                }
                else if(this.rol == 'admin'){
                    parametros.cliente_id = this.datosLogin.cliente_id;
                    parametros.empresa_id = this.empresa_value;
                    parametros.sucursal_id = this.sucursal_value;
                }
                else if(this.rol == 'admin-empresa'){
                    parametros.cliente_id = this.datosLogin.cliente_id;
                    parametros.empresa_id = this.datosLogin.empresa_id;
                    parametros.sucursal_id = this.sucursal_value;
                }
                else if(this.rol == 'admin-sucursal'){
                    parametros.cliente_id = this.datosLogin.cliente_id;
                    parametros.empresa_id = this.datosLogin.empresa_id;
                    parametros.sucursal_id = this.datosLogin.sucursal_id;
                }
                else if(this.rol == 'admin-depto'){
                    parametros.cliente_id = this.datosLogin.cliente_id;
                    parametros.empresa_id = this.datosLogin.empresa_id;
                    parametros.sucursal_id = this.datosLogin.sucursal_id;
                    parametros.empleado_id = this.datosLogin.empleado_id;
                }
                else if(this.rol == 'empleado'){
                    parametros.cliente_id = this.datosLogin.cliente_id;
                    parametros.empresa_id = this.datosLogin.empresa_id;
                    parametros.sucursal_id = this.datosLogin.sucursal_id;
                    parametros.empleado_id = this.datosLogin.empleado_id;
                }
                else {
                    parametros.cliente_id = this.datosLogin.cliente_id;
                    parametros.empresa_id = this.datosLogin.empresa_id;
                    parametros.sucursal_id = this.datosLogin.sucursal_id;
                }


                parametros.fecha_min = this.fecha_min;
                parametros.fecha_max = this.fecha_max;
                


                try {
                    await apiPedidos.descargarReporte(parametros)
                    .then((response) => {
                        this.isSaving = false;
                        FileDownload(response.data, nombre);
                        this.cerrarModal();
                    })
                    .catch(async (error)=>{
                        self.isSaving = false;
                        let errorString = error.response.data;
                        if (
                            error.request.responseType === 'blob' &&
                            error.response.data instanceof Blob &&
                            error.response.data.type &&
                            error.response.data.type.toLowerCase().indexOf('json') != -1
                        ){
                            errorString = JSON.parse(await error.response.data.text());

                            if(typeof errorString.error === 'string'){
                                Notify.ErrorToast(errorString.error);
                            }else{
                                let error = Object.values(errorString.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
                                Notify.ErrorToast(leyenda);
                            }
                        }; 
                        
                    });
                } catch (e) {
                    self.isSaving = false;
                    Notify.ErrorToast("Se produjo un error al realizar la petición.");
                }

            });
        },
        descargarLayout(){
            //pendiente
        },
        guardarLayout(){
            //pendiente 
        },
        dateFormat(fecha, tipo) {
            if(!fecha) return
            let fecha2 = fecha.split("-");
            if(tipo == 'modal'){
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }else{
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
        },

        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("comida-pedido.store")
            this.permisos.update = permisosStorage.includes("comida-pedido.update")
            this.permisos.cancelar = permisosStorage.includes("comida-pedido.cancelaPedido")
        }
    },
    created() {
        this.datosLogin         = this.$session.get('usuario');
        this.rol                = this.datosLogin.rol.name;
        this.listar();
        this.permisosVista();

        if(this.rol == 'admin-depto'){
            if(this.datosLogin.empleado_id == null){
                this.bloquearVista = true;
            }
            else{
                this.bloquearVista = false;
                this.columns= [
                    {
                        label: "Cocina",
                        name: "cocina",
                        filterable: false,
                    },
                    {
                        label: "Comida",
                        name: "comida",
                        filterable: false,
                    },
                    {
                        label: "Fecha del pedido",
                        name: "fecha",
                        filterable: false,
                    },
                    {
                        label: "Costo",
                        name: "precio_empleado",
                        filterable: false,
                    },
                    {
                        label: "Opciones",
                        name: "opciones",
                        filterable: false,
                        align: 'center'
                    },
                ];
            }
        }

        if(this.rol == 'empleado' ){
            this.columns= [
                {
                    label: "Cocina",
                    name: "cocina",
                    filterable: false,
                },
                {
                    label: "Comida",
                    name: "comida",
                    filterable: false,
                },
                {
                    label: "Fecha del pedido",
                    name: "fecha",
                    filterable: false,
                },
                {
                    label: "Costo",
                    name: "precio_empleado",
                    filterable: false,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    filterable: false,
                    align: 'center'
                },
            ];
        }
    }
}

</script>

<style scoped>
    /* #tableScroll tbody {
        display: block;
        max-height: 60vh;
        min-width: 1850px;
        background-color: #fdfcfc;
    } */
    .subtitulos{
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0px;
        color: #828282;
    }
    .precio{
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        color:#333333;

    }
    .background-area{
        background-color: #fff;
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        border-radius: 10px;
        border:1px solid #E0E0E0;
    }
    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #FFF;
    }
    .btnAddContenedor {
        width: 250px;
        height: 140px;
        padding-top: 30px;
        background-color: #FFF;
    }
    div.btnAccionesTitulo {
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesTitulo span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #1E2245;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
    .btnCancelar {
        height: 42px !important;
        border-radius: 30px;
        text-transform: none;
        background:#D41010 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }

    .header-cancelar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }
    .header-cancelar-h2 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .header-cancelar-h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 17px;
        color: #828282;
    }


    .menuClassAccionesVer{
        border-radius: 10px!important;
    }
    .menu-width{
        max-width: 500px;
        min-width: 450px;
        border-radius: 20px !important;
    }
    .v-btn-cerrar:hover:before {
      background-color: transparent;
      
    }
    .v-btn-cerrar:before {
        display: none;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .informacionCancelacion{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
    }
    .canceladoText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color:#D41010 !important;
    }
    .totales{

    }
    .totales-table {
        border-radius: 15px;
        background-color: #FFFFFF;
        padding: 20px;

    }
    .totales-tbody {

    }
    .totales-tr{

    }
    .totales-td01{
        padding: 5px 30px 5px 5px;
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #4F4F4F;
    }
    .totales-td02{
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: right;
        color: #4F4F4F;
    }
</style>


